.profile-text-box {
    width: 250px;
    height: 30px;
    padding: 4px;
    font-size: 13px;
    line-height: 18px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.profile-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.profile-field-name {
    font-weight: bold;
    font-size: 13px;
    width: 400px;
    margin-right: 20px;
}

.profile-field-hint {
    color: #003f81;
    font-size: 11px;
    width: 200px;
    margin-left: 20px;
}

@media (max-width:768px) {
    
    .profile-field {
        flex-direction: column;
        align-items: start;
    }

    .profile-field-hint {
        margin-left: 0;
    }

    .profile-field-name {
        width: 100%;
    }
}