.proveedores-user-icon-container {
    font-size: x-large;
}

.proveedores-user-icon-active {
    color: green
}

.proveedores-user-icon-inactive {
    color: red
}