.state-icon-container {
    color: green;
    text-align: center;
    font-size: x-large;
}

.state-icon-container-uncheck {
    color: #cccccc;
    text-align: center;
    font-size: x-large;
}

.state-icon-container-warning {
    color: #ee9003;
    text-align: center;
    font-size: x-large;
}