.card-container {
    /*border: solid 1px;*/
    display: flex;
    justify-content: space-between;
    width: 85%;
    margin-bottom: 20px;
    box-shadow: 5px 11px 15px 3px rgba(114,114,114,0.7);
    border-radius: 5px;
    padding: 10px;
    border-left: 10px solid var(--primary-color);
}