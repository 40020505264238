.info-table {
    width: 100%;
}

.info-table th {
    padding-left: 0px !important;
    text-align: center;
    border-left: solid 1px whitesmoke;
}

.info-table td {
    text-align: center;
}

.view-icon-container {
    color: #1386B3;
    text-align: center;
    font-size: x-large;
    /*border: solid 1px black;*/
    
}

.egresos-mobile-view {
    display: none;
}

@media (max-width:768px) {
    .egresos-mobile-view {
        display: block;
    }

    .egresos-desktop-view {
        display: block;
    }

    .egresos-cards-container {
        gap: 5px;
    }
}