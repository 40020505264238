.datefield-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 270px;
}

@media (max-width:768px) {
    .datefield-container {
        margin-bottom: 20px;
    }
}
