.factura-estado-icon-container {
    color: green;
    text-align: center;
    font-size: x-large;
}

.factura-estado-icon-container-uncheck {
    color: #cccccc;
    text-align: center;
    font-size: x-large;
}

.facturas-datefields-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-around;
    margin-top: 20px;
}

.facturas-button-container {
    width: 200px;
}

@media (max-width:768px) {
    .info-table {
        display: none;
    }

    .facturas-datefields-container {
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;        
    }

    .facturas-button-container {
        width: 100%;
        text-align: center;
    }

    

    /*#################################*/
    
    /*#################################*/
}