.admins-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    /*border: 1px solid black;*/
}

.admins-field-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*border: 1px solid black;*/
}

.admins-field-name {
    font-weight: bold;
    font-size: 13px;
    width: 150px;
    margin-right: 20px;
    /*border: 1px solid black;*/
}

.admins-field-hint {
    color: #003f81;
    font-size: 11px;
    width: 200px;
    margin-left: 20px;
    /*border: 1px solid black;*/
}

.admins-text-box {
    width: 500px;
    height: 30px;
    padding: 4px;
    font-size: 13px;
    line-height: 18px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.admins-text-box-error {
    width: 500px;
    height: 30px;
    padding: 4px;
    font-size: 13px;
    line-height: 18px;
    border: 1px solid red;
    border-radius: 3px;
}

.admins-field-msg {
    color: red;
    font-size: 11px;
}