.uploaddocs-datefields-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-around;
    margin-top: 20px;
}

.textfield-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 270px;
}
