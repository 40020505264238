.co-container {
    display: flex;
    align-items: center;
    background-color: whitesmoke;
    width: 300px;
    height: 100%;
    border: 1px solid whitesmoke;
    border-radius: 5px;
    padding: 20px;
}

.co-icon-container {
    color: #1386B3;
    padding: 10px;
}

.co-title {
    font-weight: bold;
}

.co-name-container {
    display: flex;
    flex-direction: column;
    gap: 10px
}

.co-icon {
    font-size: 40px;
}

@media (max-width:768px) {
    .co-container {
        width: 100%;
    }
}