.certificado-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: whitesmoke;
    width: 300px;
    height: 100%;
    border: 1px solid whitesmoke;
    border-radius: 5px;
    gap: 20px;
}

.certificado-icon-container {
    text-align: center;
    font-size: 40px;
    color: #1386B3;
}

.certificado-title {
    font-weight: bold;
    text-align: center;
    width: 100%;
}

.certificado-row {
    z-index: 1000;
}

.certificado-col {
    z-index: 1000;
}

@media (max-width:768px) {
    .certificado-container {
        width: 100%;
    }
}