.welcome-layout-header {
    height: 180px;
    padding-left: 200px;
    padding-top: 20px;
}

.welcome-layout-company-name {
    display: none;
}

.welcome-layout-footer-container {
    position: fixed;
    width: 100%;
    /*border: solid 1px;*/
    bottom: 0;
    z-index: 2;
}

.welcome-layout-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 40px;
    background-color: var(--primary-color);
    padding-top: 7px;
    /*margin-top: 50px;*/
    padding-left: 200px;
    padding-right: 200px;
}

.welcome-layout-content {
    margin-bottom: 50px;
}

.welcome-layout-logo {
    width: 200px;
    /*background-color: var(--primary-color);*/
    padding: 20px;
    border-radius: 5px;
}

.welcome-layout-visual-logo {
    width: 80px;
}

@media (max-width:768px) {
    .welcome-layout-main {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .welcome-layout-header {
        height: 180px;
        padding-left: 0;
        padding-top: 0;
        margin-bottom: 30px;
    }

    .welcome-layout-logo {
        padding: 20px;
        border-radius: 5px;
    }

    .welcome-layout-admin-access {
        display: none;
    }

    .welcome-layout-sign {
        color: var(--custom-gray);
        width: 100%;
        text-align: center;
        font-size: 0.7rem;
    }

    .welcome-layout-footer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: var(--primary-color);
        padding: 10px;
        height: auto;
        /*margin-top: 50px;*/
    }

    .welcome-layout-company-name {
        display: block;
        width: 100%;
        text-align: center;
        color: #404040;
        font-weight: bold;
        font-size: 1.2rem;
    }
}