.reports-button {
    width: 150px;
    height: 40px;
    background: none;
    border-radius: 5px;
    border: 1px solid;
    transform:translate(0px);
    transition: all 250ms ease-in-out;
}

.reports-active-button {
    width: 150px;
    height: 40px;
    background: var(--primary-color);
    border-radius: 5px;
    border: 1px solid white;
    color: white;
    transform:translate(0px);
    transition: all 250ms ease-in-out;
}

.reports-form {
    display: flex;
    align-items: center;
    gap: 20px;
}

.reports-select {
    width: 150px;
}

.reports-select-container {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 100%;
}

.reports-select-container label {
    margin: auto;
    width: 50px;
}

@media (max-width:768px) {
    .reports-type-container {
        display: flex;
    }

    .reports-form {
        flex-direction: column;
        align-items: start;
    }
}