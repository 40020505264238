.detalle-saldo-mobile-view {
    display: none;
}

@media (max-width:768px) {
    .detalle-saldo-desktop-view {
        display: none;
    }

    .detalle-saldo-mobile-view {
        display: block;
        margin-top: 20px;
    }
}